


































import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'
import ProgressLine from '@/components/ProgressLine.vue'
import Player from '@/components/common/Player.vue'
import Modal from '@/components/common/Modal.vue'
import isOS from '@/helper/detectOs'
@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton,
    Title,
    ProgressLine,
    Player,
    Modal
  }
})
export default class Welcome extends Vue {
  private continueButtonPath = ''

  private created (): void {
    if (sessionStorage.getItem('timer/quidel/5') === 'isRun') {
      this.continueButtonPath = 'quidel/5'
    }
  }

  private mounted (): void {
    if (window.innerWidth < 767 && isOS()) window.scrollTo(0, 1)
  }

  get videoUrls (): Array<string> {
    return this.$store.state.video.videoUrls[0]
  }

  // get videoUrls (): any {
  //   console.log(process.env.VUE_APP_VIDEO_START_END.split('/'))
  //   const startEend = process.env.VUE_APP_VIDEO_START_END.split('/')[0]
  //   return 'https://res.cloudinary.com/hey-expert/video/upload/v1631162783/BellaHealth/Oman/EN_2/English_w_English_Subtitles_from_Babylon_tcxvs2.mp4#t=' + startEend
  // }
}
